import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import AboutUsContainer from '../containers/AboutUsContainer';
import SliderContainer from '../containers/SliderContainer';

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
`;

const SYear = styled.span`
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 16px;
`;
const SDescription = styled.span`
    * {
        text-align: center;t
        color: #8c8c8c;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
`;

const ApieMus = ({ data }) => {
    return (
        <>
            <AboutUsContainer data={data.wpPage} />
            <SliderContainer
                title="Mūsų istorija"
                isGapped
                slidesToShow={4}
                data={data.allWpIstorijos.edges.map((item) => (
                    <SWrapper>
                        <SYear>{item.node.title}</SYear>
                        <SDescription>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: item.node.content,
                                }}
                            ></div>
                        </SDescription>
                    </SWrapper>
                ))}
            />
        </>
    );
};

export default ApieMus;

export const pageQuery = graphql`
    query {
        allWpIstorijos {
            edges {
                node {
                    content
                    title
                }
            }
        }
        wpPage(title: { eq: "Apie mus" }) {
            title
            content
            apieMusPuslapis {
                headerText
                headerPhoto {
                    localFile {
                        childrenImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    }
`;
