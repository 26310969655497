import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SPageH1Title, StyledContainer } from '../Layout/styles';
import { SDescription, SImage } from './styles';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getImage } from 'gatsby-plugin-image';

const AboutUsContainer = ({ data }) => {
    const image = data.headerPhoto
        ? getImage(data.headerPhoto.localFile)
        : null;
    return (
        <StyledContainer fluid>
            <Row>
                <Breadcrumbs title={data.title} />
                <Col xs={12} lg={6} className="p-0 pe-lg-5">
                    <SPageH1Title>{data.title}</SPageH1Title>
                    <SDescription>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                    </SDescription>
                </Col>
                <Col xs={12} lg={6}>
                    {image && <SImage image={image} alt={'about us image'} />}
                </Col>
            </Row>
        </StyledContainer>
    );
};

export default AboutUsContainer;
